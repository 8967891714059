<template>
  <v-select
    v-model="prioridade"
    :items="prioridades"
    clearable
    @click:clear="clearPrioridade"
    outlined
    dense
    item-text="tipo"
    item-value="id"
    hide-details
    label="Prioridade"
  ></v-select>
</template>

<script>
export default {
  name: "Prioridade",

  data() {
    return {
      prioridade: null,
      prioridades: [
        { id: 1, tipo: "Baixa" },
        { id: 2, tipo: "Normal" },
        { id: 3, tipo: "Urgente" },
        { id: 4, tipo: "Emergência" },
      ],
    };
  },

  watch: {
    prioridade() {
      if (this.prioridade) {
        this.$store.commit("Filtros/UPDATE_PRIORIDADE", this.prioridade);
      }
    },
  },

  methods: {
    clearPrioridade() {
      this.$store.commit("Filtros/UPDATE_PRIORIDADE", null);
      this.prioridade = null;
    },
  },

  mounted() {
    if (this.$route.query.prioridade) {
      let prioridade = this.prioridades.find(
        (item) => item.id == this.$route.query.prioridade
      );
      this.prioridade = prioridade.id;
    }
  },
};
</script>

<style></style>
